import {
    getOrderDetail,
    cancelOrder,
    wxPay,
    aliPay,
    cancelRefund,
    goodReceived
} from '@/utils/order.js';
import useClipboard from "vue-clipboard3"
export default {
    data() {
        return {
            isShowPage: false,
            isShow: false,
            checkXy: false,
            cancelSale: false,
            isPay: false,
            checkPayType: 'WXPAY',
            isToast: false,
            orderId: '',
            orderInfo: '',
            addressInfo: '',
            goodsInfo: [],
            isOperation: false,
            showGroup: false,
            backgroundDiv: {
                backgroundImage: "url(" + require("@/assets/img/user/home/back.png") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
            },
        }
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.getOrderDetailFunc();
    },
    methods: {
        // 获取订单详情
        getOrderDetailFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                id: this.orderId
            }
            getOrderDetail(data).then(res => {
                console.log('detail', res)
                this.orderInfo = res.data;
                this.addressInfo = res.data.memberAddress;
                this.goodsInfo = res.data.orderGoodItemList;
                sessionStorage.setItem('orderInfo', JSON.stringify(res.data));
                this.isShow = false;
                setTimeout(() => {
                        this.isShowPage = true;
                    }, 500)
                    // 如果订单状态是1 6 显示底部菜单
                if (this.orderInfo.status == 1 || this.orderInfo.status == 6) {
                    this.isShow = true;
                }
            })
        },
        // 过滤订单状态
        filterOrderStatusFunc(status) {
            let name = '';
            switch (status) {
                case 1:
                    name = '待付款';
                    break;
                case 2:
                    name = '待发货';
                    break;
                case 3:
                    name = '待评价';
                    break;
                case 4:
                    name = '售后中';
                    break;
                case 5:
                    name = '订单完成';
                    break;
                case 6:
                    name = '待收货';
                    break;
                case 7:
                    name = '已取消';
                    break;
                case 8:
                    name = '已关闭';
                    break;
                case 9:
                    name = '已退款';
                    break;
                case 10:
                    name = '已拒绝退款';
                    break;
                case 11:
                    name = '已确认收货';
                    break;
            }
            return name;
        },
        // 取消订单
        cancelOrderFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderNo: this.orderInfo.orderNo
            }
            cancelOrder(data).then(res => {
                console.log('取消订单', res)
                this.$toast('订单取消成功！');
                this.getOrderDetailFunc();
            })
        },
        // 申请售后
        applyRefundFunc() {
            if (this.orderInfo.status == 5) {
                this.showGroup = true;
            } else {
                this.isOperation = !this.isOperation
            }
        },
        // 跳转到申请退款页面
        goRefundFunc() {
            if (!this.checkXy) {
                this.$toast('请勾选同意以上规则！')
                return false
            }
            this.$router.push({
                path: '/afterService'
            })
        },
        // 取消申请
        cancelApplyFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderId
            }
            cancelRefund(data).then(res => {
                console.log('取消申请', res)
                this.$toast('订单取消售后成功！');
                this.getOrderDetailFunc();
            })
        },
        // 请通过，等待系统退款
        readRefundDetailFunc() {
            this.$router.push({
                path: '/afterSuccess'
            })
        },
        // 查看物流
        readLogisticsFunc() {
            this.$router.push({
                path: '/logisticsInfo',
                query: {
                    orderId: this.orderId
                }
            })
        },
        // 确认收货
        receiveGoodsFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderId
            }
            goodReceived(data).then(res => {
                console.log('确认收货', res)
                this.$toast('确认收货成功！');
                this.getOrderDetailFunc();
            })
        },
        // 复制订单号
        async copyFun(data) {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(data)
                this.$toast.success("已复制订单编号")
            } catch (e) {
                console.error(e)
            }
        },
        // 立即支付
        lijiPayFunc() {
            if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.wxPayFunc(), false)
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.wxPayFunc())
                    document.attachEvent('onWeixinJSBridgeReady', this.wxPayFunc())
                }
            } else {
                console.log('准备调用微信支付')
                this.wxPayFunc();
            }
        },
        // 调起支付
        wxPayFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderNo: this.orderInfo.orderNo,
                channel: this.orderInfo.channel
            }
            let _this = this;
            wxPay(data).then(res => {
                console.log('微信支付', res)
                    //调用微信支付
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": res.result.sdk_appid, //公众号名称，由商户传入
                        "timeStamp": res.result.sdk_timestamp, //时间戳，自1970年以来的秒数
                        "nonceStr": res.result.sdk_noncestr, //随机串
                        "package": res.result.sdk_package,
                        "signType": res.result.sdk_signtype, //微信签名方式：
                        "paySign": res.result.sdk_paysign //微信签名
                    },
                    function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            _this.$toast.success({
                                message: '支付成功！',
                                forbidClick: true,
                            });
                            window.location.reload();
                        }
                        if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            _this.$toast.fail({
                                message: '交易取消！',
                                forbidClick: true,
                            })
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                            _this.$toast('支付失败！');
                        }

                        setTimeout(() => {
                            _this.$toast.clear();
                        }, 1500);
                    }
                );
            })
        },
        // 支付宝支付
        aliPayFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderNo: this.orderInfo.orderNo,
                channel: this.orderInfo.channel
            }
            aliPay(data).then(res => {
                // alert(JSON.stringify(res))
                window.location.href = res.result;
            })
        },
        // 企业微信号
        async copyWxFun() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard('18017657468')
                this.$toast.success("已复制企业微信号")
            } catch (e) {
                console.error(e)
            }
        },
    },
}