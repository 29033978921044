<template>
  <div class="od" v-if="isShowPage">
    <div class="od-bg"></div>
    <div class="od-main">
      <div class="flex flex-left align-center od-main-one">
        <img src="../../../assets/img/order/pay-wait.png" alt="">
        <span>{{filterOrderStatusFunc(orderInfo.status)}}</span>
      </div>
      <div class="od-main-two">
        <div class="od-main-two-userinfo">
          {{addressInfo.customerName}}<span>{{addressInfo.phone}}</span>
        </div>
        <div class="flex flex-left od-main-two-address">
          <img src="../../../assets/img/order/oorder-address.png" alt="">
          <div>
            {{addressInfo.city}}{{addressInfo.area}}
          </div>
        </div>
      </div>
      <div class="od-main-three">
        <div v-for="item in goodsInfo" :key="item.id">
          <div class="flex flex-left align-center od-main-three-top">
            <div class="od-main-three-top-brand" :style="{background: 'url('+orderInfo.brandIcon+')no-repeat center center', backgroundSize: 'cover'}"></div>
            {{orderInfo.brandName}}
          </div>
          <div class="flex flex-left align-center od-main-three-m">
            <div class="od-main-three-m-img" :style="{background: 'url('+item.goodsImage+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <div class="od-main-three-m-info">
              <div class="od-main-three-m-info-name">
                <div class="od-main-three-m-info-name-tag" v-if="orderInfo.isSeckill == 1">限时抢购</div>{{item.goodName}}
              </div>
              <div class="od-main-three-m-info-guige">
                规格
              </div>
              <div class="flex flex-between od-main-three-m-info-price">
                <span class="od-main-three-m-info-price-s1">¥{{parseFloat(item.promotionPrice/100).toFixed(2)}}</span>
                <span class="od-main-three-m-info-price-s2">x{{item.goodNum}}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-right flex-wrap od-main-three-b">
            <van-button plain round class="od-main-three-b-btn" v-if="orderInfo.status == 1" @click="cancelOrderFunc">取消订单</van-button>
            <van-button plain round class="od-main-three-b-btn" v-if="orderInfo.status == 2 || orderInfo.status == 6 || orderInfo.status == 5" @click="applyRefundFunc">申请售后</van-button>
            <!-- <van-button plain round class="od-main-three-b-btn" @click="applyRefundFunc">申请售后</van-button> -->
            <van-button plain round class="od-main-three-b-btn" v-if="orderInfo.status == 4 && orderInfo.refundStatus == 0" @click="cancelApplyFunc">取消申请</van-button>
            <van-button plain round class="od-main-three-b-btn" v-if="orderInfo.status == 4 && orderInfo.refundStatus == 0" disabled>灵龙处理中</van-button>
            <van-button plain round class="od-main-three-b-btn" v-if="orderInfo.status == 4 && orderInfo.refundStatus == 1" @click="readRefundDetailFunc">申请通过，等待系统退款</van-button>
          </div>
        </div>
      </div>
      <div class="od-main-four">
        <div class="flex flex-between align-center od-main-four-list">
          <span class="od-main-four-list-l">商品总价</span>
          <span class="od-main-four-list-r">¥{{parseFloat(orderInfo.goodAmount/100).toFixed(2)}}</span>
        </div>
        <div class="flex flex-between align-center od-main-four-list">
          <span class="od-main-four-list-l">运费</span>
          <span class="od-main-four-list-r">¥{{parseFloat(orderInfo.freightCharge/100).toFixed(2)}}</span>
        </div>
        <div class="flex flex-between align-center od-main-four-list">
          <span class="od-main-four-list-l">龙珠抵扣</span>
          <span class="od-main-four-list-r">（-{{orderInfo.bonusCover}}龙珠）¥{{parseFloat(orderInfo.bonusAmount/100).toFixed(2)}}</span>
        </div>
        <div class="flex flex-between align-center od-main-four-list">
          <span class="od-main-four-list-l">优惠券</span>
          <span class="od-main-four-list-r">-¥{{parseFloat(orderInfo.couponCoverAmount/100).toFixed(2)}}</span>
        </div>
        <div class="od-main-four-line"></div>
        <div class="flex flex-between align-center od-main-four-price">
          <span class="od-main-four-price-l">实付款</span>
          <span class="od-main-four-price-r">¥{{parseFloat((orderInfo.goodAmount + orderInfo.freightCharge - orderInfo.bonusAmount - orderInfo.couponCoverAmount)/100).toFixed(2)}}</span>
        </div>
      </div>
      <!-- <div class="flex flex-between align-center od-main-five">
        <span class="od-main-five-l">发票</span>
        <span class="od-main-five-r">确认收货后开票</span>
      </div> -->
      <div class="od-main-six">
        <div class="od-main-six-title">订单信息</div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">订单编号</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderInfo.orderNo}}<img src="../../../assets/img/order/copy.png" alt="" @click="copyFun(orderInfo.orderNo)">
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">下单时间</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderInfo.gmtCreate}}
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">付款时间</span>
          <div class="flex flex-right align-center od-main-six-list-r" v-if="orderInfo.payTimeStr">
            {{orderInfo.payTimeStr}}
          </div>
          <div class="flex flex-right align-center od-main-six-list-r" v-else>
            —— ——
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">发货时间</span>
          <div class="flex flex-right align-center od-main-six-list-r" v-if="orderInfo.deliverTime">
            {{orderInfo.deliverTime}}
          </div>
          <div class="flex flex-right align-center od-main-six-list-r" v-else>
            —— ——
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">确认收货时间</span>
          <div class="flex flex-right align-center od-main-six-list-r" v-if="orderInfo.receiptTimeStr">
            {{orderInfo.receiptTimeStr}}
          </div>
          <div class="flex flex-right align-center od-main-six-list-r" v-else>
            —— ——
          </div>
        </div>
      </div>
      <div class="od-main-six" v-if="orderInfo.userRefund != null && orderInfo.userRefund != 0">
        <div class="od-main-six-title">售后信息</div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">售后编号</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderInfo.orderNo}}<img src="../../../assets/img/order/copy.png" alt="" @click="copyFun(orderInfo.orderNo)">
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">申请时间</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderInfo.userRefundTime}}
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">申请结果</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            <span v-if="orderInfo.status == 4">提交成功</span>
            <span v-if="orderInfo.status == 9">已退款</span>
            <span v-if="orderInfo.status == 10">已拒绝退款</span>
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">通过时间</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{orderInfo.userRefundTimeStr}}
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">退款时间</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            {{ orderInfo.refundTimeStr != null ? orderInfo.refundTimeStr : '—— ——'}}
          </div>
        </div>
        <div class="flex flex-between align-center od-main-six-list">
          <span class="od-main-six-list-l">实际退款金额</span>
          <div class="flex flex-right align-center od-main-six-list-r">
            ¥{{parseFloat(orderInfo.refundAmount/100).toFixed(2)}}
          </div>
        </div>
      </div>
    </div>
    <!-- 底部操作按钮 -->
    <van-popup v-model:show="isShow" position="bottom" round safe-area-inset-bottom :overlay="false" :lock-scroll="false">
      <div class="flex flex-right od-popup">
        <van-button plain round class="od-popup-btn" v-if="orderInfo.status == 6" @click="readLogisticsFunc">查看物流</van-button>
        <van-button round color="#E62129" class="od-popup-btn" v-if="orderInfo.status == 6" @click="receiveGoodsFunc">确认收货</van-button>
        <van-button round color="#E62129" class="od-popup-btn" v-if="orderInfo.status == 1" @click="isPay = true;">继续支付</van-button>
      </div>
    </van-popup>
    <!-- 操作影响 -->
    <div class="od-operation" v-if="isOperation">
      <div class="od-operation-main">
        <div class="od-operation-main-top">
          <div class="flex flex-center align-center od-operation-main-top-div">
            <span></span>
            操作影响
            <span></span>
          </div>
          <div class="flex flex-center align-center od-operation-main-top-d2">
            选择换货操作不会影响<br/>
            您成为种草达人享受的达人权益
          </div>
        </div>
        <div class="od-operation-main-bot">
          <div class="flex flex-center align-center od-operation-main-top-div od-operation-main-bot-top-div">
            <span></span>
            仅针对288元种草商品的订单<br/>
            退货及退货退款处理成功后
            <span></span>
          </div>
          <div class="od-operation-main-bot-two">
            <p>1. 您将无法升级至种草达人，无法享受达人专属商品特价</p>
            <p>2. 您将无法享受达人专属佣金奖励</p>
          </div>
          <div class="flex flex-center od-operation-main-bot-three">
            <van-checkbox v-model="checkXy" checked-color="#E62129">已阅读并同意以上规则</van-checkbox>
          </div>
          <div class="flex flex-between od-operation-main-bot-four">
            <van-button plain round style="width:100%;margin-right: 20px;" @click="applyRefundFunc">取消</van-button>
            <van-button plain round color="#E62129" style="width:100%;" @click="goRefundFunc">继续</van-button>
          </div>
          <div class="flex flex-between align-center od-operation-main-bot-line">
            <img src="../../../assets/img/order/lianjie.png" alt="">
            <img src="../../../assets/img/order/lianjie.png" alt="">
          </div>
          <div class="od-operation-main-bot-close" @click="applyRefundFunc">
            <img src="../../../assets/img/close1.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 取消售后申请 -->
    <van-popup round position="center" v-model:show="cancelSale">
      <div class="cancel-apply">
        <div class="flex flex-center align-center od-operation-main-top-div">
          <span></span>
          取消售后申请
          <span></span>
        </div>
        <div class="flex flex-center align-center cancel-apply-info">
          取消售后申请后，此订单将会<br/>
          恢复正常订单状态处理<br/>
          请确认是否需要取消申请
        </div>
        <div class="flex flex-between cancel-apply-btn">
          <van-button plain round class="cancel-apply-btn1">取消申请</van-button>
          <van-button round color="#E62129" class="cancel-apply-btn1">按错了</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 支付申请 -->
    <van-popup v-model:show="isPay" round position="bottom" :safe-area-inset-bottom="true" :close-on-click-overlay="false">
      <div class="pay">
        <div class="pay-title">继续支付</div>
        <div class="pay-price">¥{{parseFloat((orderInfo.goodAmount + orderInfo.freightCharge - orderInfo.bonusAmount - orderInfo.couponCoverAmount)/100).toFixed(2)}}</div>
        <van-radio-group v-model="checkPayType">
          <div class="flex flex-between align-center pay-type">
            <div class="pay-type-l">
              <img src="../../../assets/img/order/wx-pay.png" alt="">微信支付
            </div>
            <van-radio name="WXPAY" checked-color="#E62129"></van-radio>
          </div>
          <!-- <div class="flex flex-between align-center pay-type">
            <div class="pay-type-l">
              <img src="../../../assets/img/order/alipay-png.png" alt="">支付宝支付
            </div>
            <van-radio name="ALIPAY" checked-color="#E62129"></van-radio>
          </div> -->
        </van-radio-group>
        <div class="pay-btn">
          <van-button color="#E62129" round size="large" style="height:100%;" @click="lijiPayFunc">立即支付</van-button>
        </div>
        <div class="pay-close" @click="isPay = false;"></div>
      </div>
    </van-popup>
    <!-- 提示 -->
    <van-popup round position="center" v-model:show="isToast">
      <div class="od-toastmain">
        <div class="flex flex-center align-center od-toastmain-title">
          <span></span>
          提示
          <span></span>
        </div>
        <div class="od-toastmain-msg">是否放弃本次付款</div>
        <div class="od-toastmain-btn">
          <van-button class="od-toastmain-btn-list" plain type="default">放弃</van-button>
          <!-- <van-button class="od-toastmain-btn-list" plain type="default" color="#E62129">继续支付</van-button> -->
          <van-button class="od-toastmain-btn-list" plain type="default" color="#E62129">确认</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 弹窗企业微信 -->
    <van-popup
      v-model:show="showGroup"
      position="center"
      :style="{ width: '80%' }"
      :close-on-click-overlay="false"
      class="add-serve-popup"
      closeable
      round
    >
      <div class="bottom" :style="backgroundDiv">
        <div class="welcome">
          <p class="flex flex-center align-center">加灵龙企业客服微信</p>
          <p class="flex flex-center align-center">
            领取更多活动优惠，一起讨论如何更省钱
          </p>
        </div>
        <div class="qr-code">
          <img src="@/assets/img/user/home/enterprise.jpg" />
        </div>
        <p class="qr-bottom">截图此二维码，用微信扫码关注企业客服</p>
        <p class="copy" @click="copyWxFun">复制企业微信号</p>
      </div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style>
  .od-operation-main-bot-three .van-checkbox__label {
      color: #60656E;
  }
</style>
<style lang="scss" scoped>
  @import "./index.scss";
</style>